import { createRouter, createWebHistory } from 'vue-router';

import { frontendURL } from '../helper/URLHelper';
import dashboard from './dashboard/dashboard.routes';
import store from 'dashboard/store';
import { validateLoggedInRoutes } from '../helper/routeHelpers';
import AnalyticsHelper from '../helper/AnalyticsHelper';
import { buildPermissionsFromRouter } from '../helper/permissionsHelper';
import Auth from '../api/auth';


const routes = [...dashboard.routes];

export const router = createRouter({ history: createWebHistory(), routes });
export const routesWithPermissions = buildPermissionsFromRouter(routes);

export const validateAuthenticateRoutePermission = (to, next) => {
  const { isLoggedIn, getCurrentUser: user } = store.getters;
  const { 'two-factor-authentication-token': twoFactorAuthToken} = Auth.getAuthData();

  if (!isLoggedIn) {
    window.location.assign('/app/login');
    return '';
  }

  if (
    !to.name ||
    (user.two_factor_enabled && to.name === '2fa_verify' && twoFactorAuthToken)
  ) {
    return next(frontendURL(`accounts/${user.account_id}/dashboard`));
  }

  if (
    user.two_factor_enabled &&
    to.name !== '2fa_verify' &&
    !twoFactorAuthToken
  ) {
    return next(frontendURL(`accounts/${user.account_id}/2fa/verify`));
  }

  const nextRoute = validateLoggedInRoutes(to, store.getters.getCurrentUser);
  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

export const initalizeRouter = () => {
  const userAuthentication = store.dispatch('setUser');

  router.beforeEach((to, _from, next) => {
    AnalyticsHelper.page(to.name || '', {
      path: to.path,
      name: to.name,
    });

    userAuthentication.then(() => {
      return validateAuthenticateRoutePermission(to, next, store);
    });
  });
};

export default router;
