<script>
// utils and composables
import { verify2FA, resend2FACode } from '../../../../../v3/api/auth';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

// components
import FormInput from '../../../../../v3/components/Form/Input.vue';
import Spinner from 'shared/components/Spinner.vue';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';


export default {
  components: {
    FormInput,
    Spinner,
    WootSubmitButton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      twoFactorCode: '',
      verifyApi: {
        showLoading: false,
        hasErrored: false,
        message: '',
      },
      error: '',
      resendApi: {
        showLoading: false,
        message: '',
        hasErrored: false,
      },
    };
  },
  validations() {
    return {
      twoFactorCode: {
        required,
      },
    };
  },
  methods: {
    showAlertMessage(message) {
      // Show the alert message
      this.verifyApi.showLoading = false;
      this.verifyApi.message = message;
      useAlert(this.verifyApi.message);
    },
    async submit2FA() {
      this.verifyApi.hasErrored = false;
      this.verifyApi.showLoading = true;

      // Call API to verify the 2FA code
      verify2FA({ token: this.twoFactorCode })
        .then((res) => {

        })
        .catch(response => {
          this.verifyApi.hasErrored = true;
          this.showAlertMessage(
            response?.message || this.$t('2FA.INVALID_CODE')
          );
        })
        .finally(() => {
          this.verifyApi.showLoading = false;
        });;
    },
    resend2FAToken() {
      this.resendApi.hasErrored = false;
      this.resendApi.showLoading = true;

      // Call API to resend the 2FA code
      resend2FACode()
        .then((response) => {
          this.resendApi.message = this.$t('2FA.RESEND_SUCCESS');
          this.showAlertMessage(this.resendApi.message);
        })
        .catch((error) => {
          this.resendApi.hasErrored = true;
          this.resendApi.message = error?.message || this.$t('2FA.RESEND_ERROR');
          this.showAlertMessage(this.resendApi.message);
        })
        .finally(() => {
          this.resendApi.showLoading = false;
        });
    },
  },
};
</script>

<template>
  <main
    class="table w-full min-h-screen bg-woot-25 sm:px-6 lg:px-8 dark:bg-slate-900"
  >
    <div class="table-cell align-middle">
      <div class="flex flex-col w-full h-full">
        <section class="max-w-5xl mx-auto">
          <h2
            class="mt-6 text-3xl font-medium text-center text-slate-900 dark:text-woot-50"
          >
            {{ $t('2FA.TITLE') }}
          </h2>
        </section>
        <section
          class="bg-white shadow sm:mx-auto mt-11 sm:w-full sm:max-w-lg dark:bg-slate-800 p-11 sm:shadow-lg sm:rounded-lg"
          :class="{
            'animate-wiggle': verifyApi.hasErrored,
          }"
        >
          <form class="space-y-5" @submit.prevent="submit2FA">
            <FormInput
              v-model="twoFactorCode"
              name="2fa_code"
              type="text"
              data-testid="2fa_input"
              required
              :label="$t('2FA.CODE_LABEL')"
              :placeholder="$t('2FA.CODE_PLACEHOLDER')"
              :has-error="v$.twoFactorCode.$error"
              @input="v$.twoFactorCode.$touch"
            />
            <WootSubmitButton
              :disabled="verifyApi.showLoading"
              :button-text="$t('2FA.SUBMIT')"
              :loading="verifyApi.showLoading"
              button-class="w-full text-center"
            />
          </form>

          <!-- Resend 2FA Token link -->
          <div v-if="!resendApi.showLoading" class="mt-3 text-center">
            <button
              @click="resend2FAToken"
              class="text-sm text-link"
              :disabled="resendApi.showLoading"
            >
              {{ $t('2FA.RESEND_LINK') }}
            </button>
          </div>

          <!-- Loading indicator while resending 2FA code -->
          <div v-if="resendApi.showLoading" class="text-center mt-3">
            <Spinner />
          </div>

          <!-- Error or success message for resending -->
          <div v-if="resendApi.message" class="text-center mt-2">
            <p v-if="resendApi.hasErrored" class="text-red-500">
              {{ resendApi.message }}
            </p>
            <p v-else class="text-green-500">
              {{ resendApi.message }}
            </p>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
